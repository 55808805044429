"use strict";
import "./header.scss";

let instance;

export default class Header {
    constructor(el) {
        if (instance) {
            return instance;
        }

        instance = this;

        this.el = document.querySelector(".banner_container");

        console.log(this.el);
    }

    getHeight() {
        return this.el.offsetHeight;
    }

    isTransparent() {
        return this.el.classList.contains("transparent");
    }
}
