//Import Core Styles
import "./app.scss";

import Header from "./core/header/header";
import Hero from "./hero/hero";

//Core JS Functions
import "./core/global";

import css from "uikit/dist/css/uikit.css";
import Icons from "uikit/dist/js/uikit-icons";
import UIkit from "uikit/dist/js/uikit.js";

// loads the Icon plugin
UIkit.use(Icons);

//JS Plugins
//import './plugins/map-controller'
//import './plugins/testimonials'

(function () {
    //Initialise Header
    new Header(document.querySelector("header[uk-sticky]"));

    //Initialise Banner Module
    let banner = document.querySelector(".banner_container");
    if (banner !== null) {
        new Hero(banner);
    }
})();

// Gravity Forms - Add UIKIT classes to form elements
//
function domReady(fn) {
    // If we're early to the party
    document.addEventListener("DOMContentLoaded", fn);
    // If late; I mean on time.
    if (
        document.readyState === "interactive" ||
        document.readyState === "complete"
    ) {
        fn();
        const form = document.querySelectorAll(".gform_wrapper form");
        if (form) {
            form.forEach((e) => e.classList.add("uk-form"));
        }
        const fieldset = document.querySelectorAll(".gform_wrapper fieldset");
        if (fieldset) {
            fieldset.forEach((e) => e.classList.add("uk-fieldset"));
        }
        const legend = document.querySelectorAll(".gform_wrapper legend");
        if (legend) {
            legend.forEach((e) => e.classList.add("uk-legend"));
        }
        const input = document.querySelectorAll(".gform_wrapper input");
        if (input) {
            input.forEach((e) => e.classList.add("uk-input"));
        }
        const select = document.querySelectorAll(".gform_wrapper select");
        if (select) {
            select.forEach((e) => e.classList.add("uk-select"));
        }
        const textarea = document.querySelectorAll(".gform_wrapper textarea");
        if (textarea) {
            textarea.forEach((e) => e.classList.add("uk-textarea"));
        }
        const radio = document.querySelectorAll(
            '.gform_wrapper input[type="radio"]'
        );
        if (radio) {
            radio.forEach((e) => e.classList.add("uk-radio"));
        }
        const checkbox = document.querySelectorAll(
            '.gform_wrapper input[type="checkbox"]'
        );
        if (checkbox) {
            checkbox.forEach((e) => e.classList.add("uk-checkbox"));
        }
        const button = document.querySelectorAll(".gform_wrapper .button");
        if (button) {
            button.forEach((e) =>
                e.classList.add("uk-button", "uk-button-secondary")
            );
        }
    }
}

domReady(() => console.log("DOM is ready, come and get it!"));
